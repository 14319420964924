/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    // Bail if require bundle or server variables not loaded
    if ( ! RequireBundle || ! fluidthemeSettings ) return;

    var settings = fluidthemeSettings,
        verParent =fluidthemeSettings.assetsVersion,
        verChild = fluidthemeSettings.assetsVersionChild;

    // Theme Style bundles
    RequireBundle.deregister( 'post-styles' );
    RequireBundle.deregister( 'navigation-styles' );
    RequireBundle.deregister( 'comment-styles' );

    RequireBundle.register( 'post-styles', [ settings.cssPathChild + 'post' + verChild + '.min.css' ], '.blog-posts__list,.post-excerpt,.single-post' );
    RequireBundle.register( 'navigation-styles', [ settings.cssPathChild + 'navigation' + verChild + '.min.css' ], '.pagination,.woocommerce-pagination,.nav-links,.page-links' );
    RequireBundle.register( 'comment-styles', [ settings.cssPathChild + 'comment' + verChild + '.min.css' ], '#comments' );
    RequireBundle.register( 'jewel-details-styles', [ settings.cssPathChild + 'jewel-details' + verChild + '.min.css' ], '[data-modal="#modal-jewel-details"]' );

    // Helper Libraries
    RequireBundle.register( 'tocca', [ settings.jsLibPathChild + 'Tocca' + verChild + '.min.js' ]);
    RequireBundle.register( 'event-helper', [ settings.jsLibPathChild + 'event-helper.min.js' ]);

    // Scripts
    RequireBundle.register( 'multi-view', [ settings.cssPathChild + 'multi-view' + verChild + '.min.css' , settings.jsLibPathChild + 'multi-view.min.js' ], '[data-multi-view]', function(){ MultiView.init( settings.multiViews.touchEvents ); } );
    RequireBundle.register( 'modals', [ settings.cssPathChild + 'modals' + verChild + '.min.css' , settings.jsLibPathChild + 'modals' + verChild + '.min.js', settings.jsPathChild + 'modals-init' + verChild + '.min.js' ], '[data-modal],[data-modal-window]', function(){ modalsInit.init(); } );
    RequireBundle.register( 'scroll-down-button', [ settings.jsPathChild + 'scroll-down-button' + verChild + '.min.js' ], '.button-scroll', function(){ ScrollDownButton.init(); } );

})();
